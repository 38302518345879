import * as React from "react"
import Box from "@material-ui/core/Box"
import Popover from "@material-ui/core/Popover"
import ToggleComp from "./toggleComp"
import OrderSearch from "./orderSearch"
import TableSelect from "./tableSelect"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  filterBox: { marginBottom: "0.5rem" },
  popoverBox: {},
  popoverPaper: {
    marginTop: "0.5rem",
    borderRadius: "1rem",
    borderStyle: "solid",
    borderColor: theme.palette.common.blackNine,
    borderWidth: "1px",
    backgroundColor: `${theme.palette.common.blackNine}b0`,
    backdropFilter: "blur(5px)",
    "-webkit-backdrop-filter": "blur(5px)",
  },
}))

const orderTypeOptions = [
  { value: "anyType", label: "Any Type", popBox: false },
  { value: "dineIn", label: "Dine-In", popBox: false },
  { value: "takeAway", label: "Take Away", popBox: false },
  { value: "byTable", label: "By Table", popBox: true },
  { value: "byOrderNo", label: "By Order #", popBox: true },
]

const orderStateOptions = [
  { value: "anyState", label: "Any State", popBox: false },
  { value: "Received", label: "Received", popBox: false },
  { value: "Preparing", label: "Preparing", popBox: false },
  { value: "Ready", label: "Ready", popBox: false },
  { value: "Done", label: "Done", popBox: false },
]

const FilterSection = ({
  selectedOrderTypeOption,
  setSelectedOrderTypeOption,
  selectedOrderStateOption,
  setSelectedOrderStateOption,
  storeSettings,
  selectedTable,
  setSelectedTable,
  searchNum,
  setSearchNum,
  setSelectedOrder,
  ordersIn,
  user,
  setAlarm,
  setOrdersIn,
  kitchenSettings,
  dispatchFeedback,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popOverName, setPopOverName] = React.useState(null)

  const classes = useStyles()

  const handleSelectedOrderTypeChange = (event, newSelection) => {
    if (
      newSelection === "byTable" ||
      (!newSelection && selectedOrderTypeOption.value === "byTable")
    ) {
      setPopOverName("byTable")
    } else if (
      newSelection === "byOrderNo" ||
      (!newSelection && selectedOrderTypeOption.value === "byOrderNo")
    ) {
      setPopOverName("byOrderNo")
    }

    if (
      newSelection &&
      newSelection !== "byTable" &&
      newSelection !== "byOrderNo"
    ) {
      setSearchNum(0)
      setSelectedTable(null)
      setPopOverName(null)
      setSelectedOrder({})
      let defaultOpt = orderTypeOptions.find(
        element => element.value === newSelection
      )
      setSelectedOrderTypeOption({
        value: newSelection,
        label: defaultOpt.label,
      })
    }
  }

  const handleSelectedOrderStateChange = (event, newSelection) => {
    if (newSelection) {
      setSelectedOrder({})
      let defaultOpt = orderStateOptions.find(
        element => element.value === newSelection
      )
      setSelectedOrderStateOption({
        value: newSelection,
        label: defaultOpt.label,
      })
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <Box>
      <Box classes={{ root: classes.filterBox }}>
        <ToggleComp
          optionsIn={orderTypeOptions}
          selectedItem={selectedOrderTypeOption}
          handleSelectedChange={handleSelectedOrderTypeChange}
          setAnchorEl={setAnchorEl}
        />
      </Box>
      <Box classes={{ root: classes.filterBox }}>
        <ToggleComp
          optionsIn={orderStateOptions}
          selectedItem={selectedOrderStateOption}
          handleSelectedChange={handleSelectedOrderStateChange}
          setAnchorEl={setAnchorEl}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.popoverPaper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box classes={{ root: classes.popoverBox }}>
          {popOverName === "byOrderNo" ? (
            <OrderSearch
              searchNum={searchNum}
              setSearchNum={setSearchNum}
              setSelectedOrderTypeOption={setSelectedOrderTypeOption}
              setSelectedOrder={setSelectedOrder}
              handleClose={handleClose}
              ordersIn={ordersIn}
              setSelectedOrderStateOption={setSelectedOrderStateOption}
              user={user}
              setAlarm={setAlarm}
              setOrdersIn={setOrdersIn}
              kitchenSettings={kitchenSettings}
              dispatchFeedback={dispatchFeedback}
            ></OrderSearch>
          ) : (
            <></>
          )}
          {popOverName === "byTable" ? (
            <TableSelect
              storeSettings={storeSettings}
              selectedTable={selectedTable}
              setSelectedTable={setSelectedTable}
              setSelectedOrderTypeOption={setSelectedOrderTypeOption}
              setSelectedOrderStateOption={setSelectedOrderStateOption}
              setSelectedOrder={setSelectedOrder}
              handleClose={handleClose}
            ></TableSelect>
          ) : (
            <></>
          )}
        </Box>
      </Popover>
    </Box>
  )
}

export default FilterSection
