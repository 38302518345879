import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainBox: { marginBottom: "1rem" },
  mainGrid: { backgroundColor: theme.palette.common.black },
  fullWidth: { width: "100%" },
  butText: {
    fontSize: "2rem",
    fontWeight: 400,
  },
  but: {
    borderRadius: "5rem",
    width: "4rem",
    height: "4rem",
    backgroundColor: theme.palette.common.blackTwo,
    color: theme.palette.common.offWhite,
    "&:hover": {
      backgroundColor: `${theme.palette.common.blackFour} !important`,
    },
  },

  butDisabled: {
    backgroundColor: `${theme.palette.common.blackOne} !important`,
    color: `${theme.palette.common.blackFour} !important`,
  },

  butDelDisabled: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.blackFour} !important`,
  },

  butDel: {
    borderRadius: "5rem",
    width: "4rem",
    height: "4rem",
    color: theme.palette.common.offWhite,
    backgroundColor: theme.palette.common.black,
    "&:hover": {
      backgroundColor: `${theme.palette.common.blackFour} !important`,
    },
  },

  mainNumBox: {
    display: "inline-flex",
    verticalAlign: "middle",
    justifyContent: "center",
    borderRadius: "5rem",
    width: "8.5rem",
    height: "4rem",
    backgroundColor: theme.palette.common.black,
  },

  mainNumText: {
    alignSelf: "center",
    fontSize: "1.75rem",
    lineHeight: "1rem",
    fontWeight: 400,
  },
}))

const NumInput = ({ num, setNum, children, isDisabled }) => {
  const handleClick = numIn => {
    if (num === 0) {
      setNum(numIn)
    } else if (num <= 999999) {
      let newNum = num * 10 + numIn
      setNum(newNum)
    }
  }

  const handleDel = () => {
    if (num !== 0) {
      if (num < 10) {
        setNum(0)
      } else {
        let newNum = parseInt(num.toString().slice(0, -1))
        setNum(newNum)
      }
    }
  }

  const classes = useStyles()
  return (
    <Box classes={{ root: classes.mainBox }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={8}>
              <Box classes={{ root: classes.mainNumBox }}>
                <Typography classes={{ root: classes.mainNumText }}>
                  {num}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{
                  root: classes.butDel,
                  disabled: classes.butDelDisabled,
                }}
                onClick={() => handleDel()}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"<"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(1)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"1"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(2)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"2"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(3)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"3"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(4)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"4"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(5)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"5"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(6)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"6"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(7)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"7"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(8)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"8"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(9)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"9"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={4}>
              <Button
                disabled={isDisabled}
                disableElevation
                fullWidth
                variant="contained"
                size="large"
                classes={{ root: classes.but, disabled: classes.butDisabled }}
                onClick={() => handleClick(0)}
              >
                <Typography classes={{ root: classes.butText }}>
                  {"0"}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={8}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NumInput
