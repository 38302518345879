import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"

import SingleOrderItemLine from "../singleOrderItemLine"

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  inGrid: {
    width: "100%",
    minHeight: "2.6rem",
    backgroundColor: theme.palette.common.black,
    borderRadius: "0.6rem",
    marginBottom: "0.5rem",
    overflow: "auto",
    position: "relative",
  },

  contBox: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: 0,
    marginTop: "-0.6rem",
    paddingBottom: "0.5rem",
  },

  headerText: {
    fontSize: "1.25rem",
  },

  contBoxHide: {
    display: "none",
  },

  titBox: {
    width: "100%",
    position: "sticky",
    top: 0,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderRadius: "0.5rem",
    cursor: "pointer",
    backgroundColor: theme.palette.common.blackFour,
  },

  expandMoreIcon: {
    rotate: "0deg",
    transition: "all 0.25s",
  },

  expandMoreIconInv: {
    rotate: "180deg",
    transition: "all 0.25s",
  },

  moreButton: {
    height: "1.5rem",
    width: "1.5rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blackThree,
  },

  quantityHeading: {
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "0.75rem",
  },

  quantityChip: {
    paddingTop: "0.45rem",
    paddingBottom: "0.35rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "2rem",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blackFour,
  },

  quantityChipRed: {
    backgroundColor: theme.palette.common.darkOrange,
  },

  statusBoxReceived: {
    backgroundColor: `${theme.palette.common.received} !important`,
  },
  statusBoxPreparing: {
    backgroundColor: `${theme.palette.common.preparing} !important`,
  },
  statusBoxReady: {
    backgroundColor: `${theme.palette.common.ready} !important`,
  },
  statusBoxDone: {
    backgroundColor: `${theme.palette.common.done} !important`,
  },
}))

const OrderItemLine = ({ selectedOrder, section }) => {
  const [isMoreVisible, setIsMoreVisible] = React.useState(true)

  const classes = useStyles()
  return (
    <Grid item classes={{ root: classes.inGrid }}>
      <Box
        classes={{ root: classes.titBox }}
        onClick={e => {
          setIsMoreVisible(!isMoreVisible)
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Box
                  classes={{
                    root: clsx(classes.quantityChip, {
                      [classes.statusBoxReceived]:
                        section.sectionStatus === "Received",
                      [classes.statusBoxPreparing]:
                        section.sectionStatus === "Preparing",
                      [classes.statusBoxReady]:
                        section.sectionStatus === "Ready",
                      [classes.statusBoxDone]: section.sectionStatus === "Done",
                    }),
                  }}
                >
                  <Typography
                    variant="h6"
                    classes={{ root: classes.quantityHeading }}
                  >
                    {`${section.sectionItemsCount}x`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Typography variant="h6" classes={{ root: classes.headerText }}>
                  {section.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar classes={{ root: classes.moreButton }}>
              <ExpandMoreOutlinedIcon
                classes={{
                  root: clsx(classes.expandMoreIcon, {
                    [classes.expandMoreIconInv]: isMoreVisible,
                  }),
                }}
              />
            </Avatar>
          </Grid>
        </Grid>
      </Box>
      <Box
        classes={{
          root: clsx(classes.contBox, {
            [classes.contBoxHide]: !isMoreVisible,
          }),
        }}
      >
        {selectedOrder.items.map((item, index) =>
          item.section.slug === section.slug ? (
            <SingleOrderItemLine key={index} item={item} />
          ) : null
        )}
      </Box>
    </Grid>
  )
}

export default OrderItemLine
