import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Avatar from "@material-ui/core/Avatar"
import Badge from "@material-ui/core/Badge"
import LinearProgress from "@material-ui/core/LinearProgress"

import { makeStyles, withStyles } from "@material-ui/core/styles"

import getTimeText from "../helperFunc/getTimeText"
import getHrMinText from "../helperFunc/getHrMinText"

const useStyles = makeStyles(theme => ({
  orderListItemBoxOuter: {
    cursor: "pointer",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    borderRadius: "0.5rem",
    padding: "0.125rem",
    height: "11rem",
    backgroundColor: theme.palette.common.black,
  },
  orderListItemBox: {
    position: "relative",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    height: "100%",
    textAlign: "-webkit-center",
    backgroundColor: theme.palette.common.blackTwo,
  },
  selectedOrderListItemBoxOuter: {
    backgroundColor: `${theme.palette.common.redSelected} !important`,
  },
  selectedOrderListItemBox: {
    backgroundColor: `${theme.palette.common.blackOne} !important`,
  },

  dineInMainBox: {
    backgroundColor: `${theme.palette.common.dineIn} !important`,
  },

  selectedDineInMainBox: {
    backgroundColor: `${theme.palette.common.dineInDark} !important`,
  },

  orderNoText: {
    fontSize: "1.25rem",
    marginBottom: "0.25rem",
    fontWeight: 400,
  },

  dineInBox: {
    borderRadius: "2rem",
    backgroundColor: theme.palette.common.dineInMed,
  },

  typeText: {
    fontWeight: 400,
  },

  textLabel: {
    marginBottom: "0.25rem",
    fontSize: "0.75rem",
    color: theme.palette.common.blackNine,
  },

  takeAwayBox: {
    borderRadius: "2rem",

    backgroundColor: theme.palette.common.blackFour,
  },

  takeAwayBoxWarning: {
    backgroundColor: `${theme.palette.common.darkOrange} !important`,
  },
  takeAwayBoxDanger: {
    backgroundColor: `${theme.palette.common.darkRed} !important`,
  },

  sectionAvatar: {
    backgroundColor: theme.palette.common.blackThree,
    color: theme.palette.common.blackOne,
    height: "1.25rem",
    width: "1.25rem",
  },
  sectionAvatarDineIn: {
    backgroundColor: `${theme.palette.common.dineInMed} !important`,
  },
  sectionAvatarReceived: {
    backgroundColor: `${theme.palette.common.received} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  sectionAvatarPreparing: {
    backgroundColor: `${theme.palette.common.preparing} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  sectionAvatarReady: {
    backgroundColor: `${theme.palette.common.ready} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  sectionAvatarDone: {
    backgroundColor: `${theme.palette.common.done} !important`,
    color: `${theme.palette.common.white} !important`,
  },

  sectionAvatarBox: {
    display: "inline-block",
    padding: "0.25rem",
  },

  sectionAvatarText: {
    fontWeight: 700,
    marginTop: "0.1rem",
  },

  footer: {
    position: "absolute",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    bottom: 0,
    left: 0,
    width: "100%",
  },

  footerTimeBox: {
    //backgroundColor: `${theme.palette.common.darkRed} !important`,
    padding: "0.25rem",
    color: theme.palette.common.seventyWhite,
  },

  footerTimeBoxWarning: {
    backgroundColor: `${theme.palette.common.darkOrange} !important`,
    color: `${theme.palette.common.white} !important`,
  },

  footerTimeBoxDanger: {
    backgroundColor: `${theme.palette.common.darkRed} !important`,
    color: `${theme.palette.common.white} !important`,
  },

  footerTimeText: {
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
  },

  statusBox: {
    padding: "0.25rem",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    backgroundColor: `${theme.palette.common.blackThree} !important`,
  },

  statusText: {
    fontWeight: 700,
    color: theme.palette.common.offWhite,
    textTransform: "uppercase",
  },

  statusBoxReceived: {
    backgroundColor: `${theme.palette.common.received} !important`,
  },
  statusBoxPreparing: {
    backgroundColor: `${theme.palette.common.preparing} !important`,
  },
  statusBoxReady: {
    backgroundColor: `${theme.palette.common.ready} !important`,
  },
  statusBoxDone: {
    backgroundColor: `${theme.palette.common.done} !important`,
  },

  progressWarning: {
    backgroundColor: `${theme.palette.common.orange} !important`,
  },

  progressDanger: {
    backgroundColor: `${theme.palette.common.red} !important`,
  },
}))

const StyledBadge = withStyles(theme => ({
  badge: {
    transform: "scale(0.75) translate(50%, -50%)",
    backgroundColor: `${theme.palette.common.offWhite} !important`,
    color: `${theme.palette.common.black} !important`,
    fontWeight: 700,
    padding: "0.25rem",
    fontSize: "1rem",
    right: 3,
  },
}))(Badge)

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 3,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.blackFour,
  },
  bar: {
    borderRadius: 0,
    backgroundColor: theme.palette.common.offWhite,
  },
}))(LinearProgress)

const OrderListItem = ({
  orderItem,
  selectedOrder,
  setSelectedOrder,
  kitchenSettings,
}) => {
  const classes = useStyles()

  return (
    <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
      <Box
        classes={{
          root: clsx(classes.orderListItemBoxOuter, {
            [classes.selectedOrderListItemBoxOuter]:
              orderItem.id === selectedOrder.id,
          }),
        }}
        onClick={e => {
          setSelectedOrder(orderItem)
        }}
      >
        <Box
          classes={{
            root: clsx(classes.orderListItemBox, {
              [classes.selectedOrderListItemBox]:
                orderItem.id === selectedOrder.id,
              [classes.dineInMainBox]: orderItem.orderType === "dineIn",
              [classes.selectedDineInMainBox]:
                orderItem.orderType === "dineIn" &&
                orderItem.id === selectedOrder.id,
            }),
          }}
        >
          <Typography variant="h5" classes={{ root: classes.orderNoText }}>
            {orderItem.orderType === "dineIn"
              ? `TBL ${orderItem.orderTableNumber}`
              : `#${orderItem.id}`}
          </Typography>
          {orderItem.orderType === "dineIn" ? (
            <>
              <Box classes={{ root: classes.dineInBox }}>
                <Typography
                  variant="body1"
                  align="center"
                  classes={{ root: classes.typeText }}
                >
                  {`# ${orderItem.id}`}
                </Typography>
              </Box>
              <Typography variant="body1" classes={{ root: classes.textLabel }}>
                {"DINE-IN"}
              </Typography>
            </>
          ) : orderItem.orderType === "takeAway" ? (
            <>
              <Box
                classes={{
                  root: clsx(classes.takeAwayBox, {
                    [classes.takeAwayBoxWarning]:
                      orderItem.timeToPickUp <
                        kitchenSettings.takeAwayMinToWarning &&
                      orderItem.timeToPickUp >= 0 &&
                      (orderItem.status === "Received" ||
                        orderItem.status === "Preparing"),
                    [classes.takeAwayBoxDanger]:
                      orderItem.timeToPickUp < 0 &&
                      (orderItem.status === "Received" ||
                        orderItem.status === "Preparing"),
                  }),
                }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  classes={{ root: classes.typeText }}
                >
                  {orderItem.status === "Received" ||
                  orderItem.status === "Preparing"
                    ? getTimeText(orderItem.timeToPickUp)
                    : `${getHrMinText(orderItem.orderPickUpTime)} ${
                        orderItem.orderPickUpDate.dayOfMonth
                      }/${orderItem.orderPickUpDate.monthInd + 1}`}
                </Typography>
              </Box>
              <Typography variant="body1" classes={{ root: classes.textLabel }}>
                {"TAKE AWAY"}
              </Typography>
            </>
          ) : (
            <></>
          )}
          {orderItem.sections.map((section, index) => (
            <Box key={index} classes={{ root: classes.sectionAvatarBox }}>
              <StyledBadge
                badgeContent={section.sectionItemsCount}
                overlap="rectangular"
              >
                <Avatar
                  classes={{
                    root: clsx(classes.sectionAvatar, {
                      [classes.sectionAvatarDineIn]:
                        orderItem.orderType === "dineIn" &&
                        section.sectionItemsCount < 1,
                      [classes.sectionAvatarReceived]:
                        section.sectionStatus === "Received" &&
                        section.sectionItemsCount > 0,
                      [classes.sectionAvatarPreparing]:
                        section.sectionStatus === "Preparing" &&
                        section.sectionItemsCount > 0,
                      [classes.sectionAvatarReady]:
                        section.sectionStatus === "Ready" &&
                        section.sectionItemsCount > 0,
                      [classes.sectionAvatarDone]:
                        section.sectionStatus === "Done" &&
                        section.sectionItemsCount > 0,
                    }),
                  }}
                >
                  <Typography
                    variant="body1"
                    classes={{ root: classes.sectionAvatarText }}
                  >
                    {section.name.slice(0, 1)}
                  </Typography>
                </Avatar>
              </StyledBadge>
            </Box>
          ))}
          <Box classes={{ root: classes.footer }}>
            {orderItem.orderType === "takeAway" &&
            (orderItem.status === "Preparing" ||
              orderItem.status === "Ready") ? (
              <Box classes={{ root: classes.footerTimeBox }}>
                <Typography
                  variant="body1"
                  classes={{ root: classes.footerTimeText }}
                >
                  {`${orderItem.status} for ${
                    orderItem.lastUpdateTimeInMin > 999
                      ? "999+"
                      : orderItem.lastUpdateTimeInMin
                  } Mins`}
                </Typography>
              </Box>
            ) : orderItem.orderType === "dineIn" &&
              (orderItem.status === "Received" ||
                orderItem.status === "Preparing") ? (
              <Box
                classes={{
                  root: clsx(classes.footerTimeBox, {
                    [classes.footerTimeBoxWarning]:
                      orderItem.lastUpdateTimeInMin >=
                        kitchenSettings.dineInMinToWarning &&
                      orderItem.lastUpdateTimeInMin <
                        kitchenSettings.dineInMinToAlarm &&
                      orderItem.status === "Received",
                    [classes.footerTimeBoxDanger]:
                      orderItem.lastUpdateTimeInMin >=
                        kitchenSettings.dineInMinToAlarm &&
                      orderItem.status === "Received",
                  }),
                }}
              >
                <Typography
                  variant="body1"
                  classes={{ root: classes.footerTimeText }}
                >
                  {`${orderItem.status} for ${
                    orderItem.lastUpdateTimeInMin > 999
                      ? "999+"
                      : orderItem.lastUpdateTimeInMin
                  } Mins`}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            {orderItem.status === "Preparing" ? (
              <BorderLinearProgress
                classes={{
                  bar: clsx({
                    [classes.progressWarning]:
                      orderItem.lastUpdateTimeInMin >
                        0.8 * kitchenSettings.maxPreparingTime &&
                      orderItem.lastUpdateTimeInMin <
                        kitchenSettings.maxPreparingTime,
                    [classes.progressDanger]:
                      orderItem.lastUpdateTimeInMin >=
                      kitchenSettings.maxPreparingTime,
                  }),
                }}
                variant="determinate"
                value={
                  orderItem.lastUpdateTimeInMin >=
                  kitchenSettings.maxPreparingTime
                    ? 100
                    : (orderItem.lastUpdateTimeInMin /
                        kitchenSettings.maxPreparingTime) *
                      100
                }
              />
            ) : (
              <></>
            )}
            <Box
              classes={{
                root: clsx(classes.statusBox, {
                  [classes.statusBoxReceived]: orderItem.status === "Received",
                  [classes.statusBoxPreparing]:
                    orderItem.status === "Preparing",
                  [classes.statusBoxReady]: orderItem.status === "Ready",
                  [classes.statusBoxDone]: orderItem.status === "Done",
                }),
              }}
            >
              <Typography
                variant="body1"
                classes={{ root: classes.statusText }}
              >
                {orderItem.status}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default OrderListItem
