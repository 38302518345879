import * as React from "react"
import axios from "axios"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../../helperFunc/handleError"
import { addSingleOrder, setLocalOrders } from "../helperFunc/mainImportFilter"

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderListItemBoxOuter: {
    marginRight: "0.35rem",
    marginBottom: "0.35rem",
    borderRadius: "0.5rem",

    height: "7rem",
    backgroundColor: theme.palette.common.black,
  },
  orderListItemBox: {
    position: "relative",
    borderRadius: "0.5rem",
    paddingTop: "0.25rem",
    height: "100%",
    textAlign: "-webkit-center",
    backgroundColor: theme.palette.common.blackTwo,
  },

  dineInMainBox: {
    backgroundColor: `${theme.palette.common.localOrdDark} !important`,
  },

  orderNoText: {
    fontSize: "1.20rem",
    lineHeight: "1.2rem",
    marginBottom: "0.4rem",
    fontWeight: 400,
  },

  textLabel: {
    marginTop: "0.25rem",
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    marginBottom: "0.35rem",
    color: theme.palette.common.seventyWhite,
  },

  footer: {
    position: "absolute",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    bottom: 0,
    left: 0,
    width: "100%",
  },

  statusBox: {
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    backgroundColor: `${theme.palette.common.blackFive} !important`,
  },

  dineInMainBoxWarning: {
    backgroundColor: `${theme.palette.common.darkOrange} !important`,
  },

  dineInMainBoxDanger: {
    backgroundColor: `${theme.palette.common.darkRed} !important`,
  },

  statusText: {
    fontWeight: 400,
    color: theme.palette.common.offWhite,
    fontSize: "0.75rem",
    textTransform: "uppercase",
  },

  butDone: {
    padding: "0.35rem",
    backgroundColor: theme.palette.common.done,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.common.darkGreen} !important`,
    },
  },

  butDoneDisabled: {
    backgroundColor: `${theme.palette.common.darkGreen} !important`,
  },

  butDoneText: {
    textTransform: "initial",
    fontSize: "0.75rem",
  },
  iconSize: {
    fontSize: "1.6rem !important",
  },
}))

const ReadyOrderListItem = ({
  orderItem,
  localOrdersIn,
  ordersIn,
  user,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  selectedOrder,
  kitchenSettings,
  setSelectedOrder,
  setLocalOrdersIn,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const classes = useStyles()

  const handleDone = orderItem => {
    setIsLoading(true)
    if (orderItem.orderType === "takeAway") {
      axios
        .post(
          process.env.GATSBY_STRAPI_URL + "/api/kitchen-setting/set-ord-status",
          {
            id: orderItem.id,
            newStatus: "Done",
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          setIsLoading(false)
          const res = addSingleOrder(ordersIn, response.data, kitchenSettings)
          if (res) {
            setOrdersIn(res.orderRes)
            setAlarm(res.alarm)
            if (selectedOrder?.id === orderItem.id) {
              setSelectedOrder(res.newOrdOut)
            }
          }
        })
        .catch(error => {
          console.error(error)
          setIsLoading(false)
          handleError(error, dispatchFeedback)
        })
    } else if (orderItem.orderType === "inStore") {
      axios
        .post(
          process.env.GATSBY_STRAPI_URL +
            "/api/kitchen-setting/remove-local-order",
          {
            orderNumber: parseInt(orderItem.orderNumber),
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          const existingOrdInd = localOrdersIn.findIndex(
            element => element.orderNumber === parseInt(response.data)
          )

          if (existingOrdInd > -1) {
            let localOrdersInCopy = [...localOrdersIn]
            localOrdersInCopy.splice(existingOrdInd, 1)
            const { localOrdersRes } = setLocalOrders(localOrdersInCopy)

            setLocalOrdersIn(localOrdersRes)
          }

          setIsLoading(false)
        })
        .catch(error => {
          handleError(error, dispatchFeedback)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Grid item xs={6} sm={3} md={3} lg={3} xl={4}>
      <Box
        classes={{ root: classes.orderListItemBoxOuter }}
        onClick={e => {
          //setSelectedOrder(orderItem)
        }}
      >
        <Box
          classes={{
            root: clsx(classes.orderListItemBox, {
              [classes.dineInMainBox]: orderItem.orderType === "inStore",
            }),
          }}
        >
          {orderItem.orderType === "inStore" ? (
            <>
              <Typography variant="body1" classes={{ root: classes.textLabel }}>
                {"IN-STORE"}
              </Typography>
            </>
          ) : orderItem.orderType === "takeAway" ? (
            <>
              <Typography variant="body1" classes={{ root: classes.textLabel }}>
                {"ONLINE"}
              </Typography>
            </>
          ) : (
            <></>
          )}
          <Typography variant="h5" classes={{ root: classes.orderNoText }}>
            {`#${
              orderItem.orderType === "inStore"
                ? orderItem.orderNumber
                : orderItem.orderType === "takeAway"
                ? orderItem.id
                : ""
            }`}
          </Typography>

          <IconButton
            disabled={isLoading}
            color="secondary"
            onClick={e => {
              handleDone(orderItem)
            }}
            classes={{
              root: classes.butDone,
              disabled: classes.butDoneDisabled,
            }}
          >
            {!isLoading ? (
              <CheckCircleOutlineOutlinedIcon
                classes={{ root: classes.iconSize }}
              />
            ) : (
              <CircularProgress color="inherit" size={"1.5rem"} />
            )}
          </IconButton>

          <Box classes={{ root: classes.footer }}>
            <Box
              classes={{
                root: clsx(classes.statusBox, {
                  [classes.dineInMainBoxWarning]:
                    orderItem.lastUpdateTimeInMin >= 20 &&
                    orderItem.lastUpdateTimeInMin < 30,
                  [classes.dineInMainBoxDanger]:
                    orderItem.lastUpdateTimeInMin >= 30,
                }),
              }}
            >
              <Typography
                variant="body1"
                classes={{ root: classes.statusText }}
              >
                {`${
                  orderItem.lastUpdateTimeInMin > 999
                    ? "999+"
                    : orderItem.lastUpdateTimeInMin
                } Mins`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default ReadyOrderListItem
