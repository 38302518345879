import * as React from "react"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Alert from "@material-ui/lab/Alert"
import NumInput from "../numInput"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import handleError from "../../../helperFunc/handleError"
import { addSingleOrder } from "../helperFunc/mainImportFilter"

const useStyles = makeStyles(theme => ({
  mainBox: { paddingTop: "1rem", paddingLeft: "1rem", paddingRight: "1rem" },
  butAction: {
    padding: "0.25rem",
    borderRadius: "5rem",
    width: "8rem",
    height: "4rem",
    backgroundColor: theme.palette.common.redSelected,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.common.redSelectedDark} !important`,
    },
  },

  butActionDisabled: {
    color: `${theme.palette.common.blackEight} !important`,
    backgroundColor: `${theme.palette.common.redSelectedDark} !important`,
  },

  butActionBox: {
    flexWrap: "wrap",
    marginTop: "0.35rem",
  },

  butActionTextMain: {
    fontSize: "1.25rem",
    lineHeight: "1rem",
    fontWeight: 600,
  },
  butActionTextSecondary: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  alert: {
    borderRadius: "1rem",

    maxWidth: "13rem",
    marginBottom: "1rem",
  },
}))

const OrderSearch = ({
  searchNum,
  setSearchNum,
  setSelectedOrderTypeOption,
  setSelectedOrder,
  handleClose,
  ordersIn,
  setSelectedOrderStateOption,
  user,
  setAlarm,
  setOrdersIn,
  kitchenSettings,
  dispatchFeedback,
}) => {
  const [numToSearch, setNumToSearch] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorText, setErrorText] = React.useState("")

  const classes = useStyles()

  const handleAdd = () => {
    setIsLoading(true)
    setErrorText("")
    const existingOrdInd = ordersIn.findIndex(
      element => element.id === numToSearch
    )
    if (existingOrdInd > -1) {
      let ordersInCopy = [...ordersIn]
      ordersInCopy[existingOrdInd].searched = true
      setSelectedOrder(ordersInCopy[existingOrdInd])
      setOrdersIn(ordersInCopy)
      setSearchNum(numToSearch)
      setSelectedOrderTypeOption({
        value: "byOrderNo",
        label: `ORDER #${numToSearch}`,
      })
      setSelectedOrderStateOption({ value: "anyState", label: "Any State" })
      handleClose()
      setIsLoading(false)
    } else {
      axios
        .post(
          process.env.GATSBY_STRAPI_URL +
            "/api/kitchen-setting/get-order-by-id",
          {
            id: numToSearch,
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          let newOrd = { ...response.data }
          newOrd.searched = true
          const res = addSingleOrder(ordersIn, newOrd, kitchenSettings, false)
          if (res) {
            setOrdersIn(res.orderRes)
            setAlarm(res.alarm)
            setSearchNum(numToSearch)
            setSelectedOrder(res.newOrdOut)
            setSelectedOrderTypeOption({
              value: "byOrderNo",
              label: `ORDER #${numToSearch}`,
            })
            setSelectedOrderStateOption({
              value: "anyState",
              label: "Any State",
            })
            handleClose()
          }
          setIsLoading(false)
        })
        .catch(error => {
          if (error.response.status === 404) {
            setErrorText(`Order #${numToSearch} Not Found!`)
            setNumToSearch(0)
          } else {
            handleError(error, dispatchFeedback)
          }
          setIsLoading(false)
          //console.error(error)
        })
    }
  }

  return (
    <Box classes={{ root: classes.mainBox }}>
      <NumInput
        num={numToSearch}
        setNum={setNumToSearch}
        isDisabled={isLoading}
      >
        <Button
          disabled={isLoading || numToSearch === 0}
          disableElevation
          fullWidth
          variant="contained"
          size="large"
          onClick={handleAdd}
          classes={{
            root: classes.butAction,
            disabled: classes.butActionDisabled,
          }}
        >
          <Box classes={{ root: classes.butActionBox }}>
            {isLoading ? (
              <CircularProgress color="inherit" size={"1.5rem"} />
            ) : (
              <>
                <Typography
                  variant="body1"
                  classes={{ root: classes.butActionTextMain }}
                >
                  {"SEARCH"}
                </Typography>
                <Typography
                  variant="body1"
                  classes={{ root: classes.butActionTextSecondary }}
                >
                  {"ORDER"}
                </Typography>
              </>
            )}
          </Box>
        </Button>
      </NumInput>
      {errorText ? (
        <Alert
          severity="error"
          variant="filled"
          classes={{ root: classes.alert }}
        >
          <Typography variant={"body1"}>{errorText}</Typography>
        </Alert>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default OrderSearch
