import * as React from "react"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"

import TableMap from "./tableMap"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  statusBoxReceived: {
    height: "2.5rem",
    marginRight: "1.5rem",
    backgroundColor: `${theme.palette.common.received} !important`,
    [theme.breakpoints.down("md")]: {
      height: "1.5rem",
    },
  },
  statusBoxPreparing: {
    height: "2.5rem",
    marginRight: "1.5rem",
    backgroundColor: `${theme.palette.common.preparing} !important`,
    [theme.breakpoints.down("md")]: {
      height: "1.5rem",
    },
  },

  statusBoxCount: {
    height: "2.5rem",
    marginRight: "0.25rem",
    backgroundColor: `${theme.palette.common.black} !important`,
    [theme.breakpoints.down("md")]: {
      height: "1.5rem",
    },
  },

  statusText: {
    fontSize: "1rem",
    fontWeight: 700,
    color: theme.palette.common.offWhite,
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.75rem",
    },
  },

  countText: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.palette.common.offWhite,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },

  chipsBox: {
    display: "flex",
    marginLeft: "1.5rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0.5rem",
    },
  },

  mainHeading: {
    marginLeft: "1.5rem",
    marginTop: "1.5rem",
    marginBottom: "0.75rem",

    [theme.breakpoints.down("md")]: {
      fontSize: "2rem !important",
      marginLeft: "0.5rem",
      marginTop: "0.5rem",
    },
  },
}))

const colours = {
  noOrders: "#000",
  received: "#606060",
  preparing: "#3e4bc5",
  done: "#228636",
  toClean: "#b86800",
}

const maxTimeForDoneTablesMin = 20
const maxTimeForToCleanTablesMin = maxTimeForDoneTablesMin + 5

const TableMapCont = ({
  selectedOrder,
  storeSettings,
  ordersIn,
  setSelectedOrderTypeOption,
  setSelectedOrderStateOption,
  setSelectedTable,
  setSelectedOrder,
  selectedTable,
}) => {
  const [tablesConfig, setTablesConfig] = React.useState(
    Array(storeSettings.tableList.length).fill({
      filCol: colours.noOrdersived,
      receivedCount: 0,
      preparingCount: 0,
      doneCount: 0,
      doubleState: false,
    })
  )

  const [dineInReceivedCount, setDineInReceivedCount] = React.useState(0)
  const [dineInPreparingCount, setDineInPreparingCount] = React.useState(0)

  React.useEffect(() => {
    let newTablesConfig = new Array(storeSettings.tableList.length)
      .fill()
      .map(u => ({
        filCol: colours.noOrdersived,
        receivedCount: 0,
        preparingCount: 0,
        doneCount: 0,
        toCleanCount: 0,
        doubleState: false,
      }))

    let receivedCount = 0
    let preparingCount = 0

    for (let i = 0; i < ordersIn.length; i++) {
      if (ordersIn[i].orderType === "dineIn") {
        if (ordersIn[i].status === "Preparing") {
          preparingCount++
          newTablesConfig[ordersIn[i].orderTableNumber - 1].preparingCount =
            newTablesConfig[ordersIn[i].orderTableNumber - 1].preparingCount + 1
        } else if (ordersIn[i].status === "Done") {
          if (ordersIn[i].lastUpdateTimeInMin <= maxTimeForDoneTablesMin) {
            newTablesConfig[ordersIn[i].orderTableNumber - 1].doneCount =
              newTablesConfig[ordersIn[i].orderTableNumber - 1].doneCount + 1
          } else if (
            ordersIn[i].lastUpdateTimeInMin <= maxTimeForToCleanTablesMin
          ) {
            newTablesConfig[ordersIn[i].orderTableNumber - 1].toCleanCount =
              newTablesConfig[ordersIn[i].orderTableNumber - 1].toCleanCount + 1
          }
        } else if (ordersIn[i].status === "Received") {
          receivedCount++
          newTablesConfig[ordersIn[i].orderTableNumber - 1].receivedCount =
            newTablesConfig[ordersIn[i].orderTableNumber - 1].receivedCount + 1
        }
      }
    }

    for (let kk = 0; kk < newTablesConfig.length; kk++) {
      if (
        newTablesConfig[kk].receivedCount > 0 &&
        newTablesConfig[kk].preparingCount > 0
      ) {
        newTablesConfig[kk].filCol = "url(#Gradient1)"
      } else if (newTablesConfig[kk].receivedCount > 0) {
        newTablesConfig[kk].filCol = colours.received
      } else if (newTablesConfig[kk].preparingCount > 0) {
        newTablesConfig[kk].filCol = colours.preparing
      } else if (newTablesConfig[kk].doneCount > 0) {
        newTablesConfig[kk].filCol = colours.done
      } else if (newTablesConfig[kk].toCleanCount > 0) {
        newTablesConfig[kk].filCol = colours.toClean
      }
    }
    setTablesConfig(newTablesConfig)
    setDineInReceivedCount(receivedCount)
    setDineInPreparingCount(preparingCount)
  }, [ordersIn, storeSettings.tableList.length])
  //console.log(tablesConfig)

  const classes = useStyles()
  return (
    <>
      <Typography variant="h4" classes={{ root: classes.mainHeading }}>
        {"DINE-IN ORDERS"}
      </Typography>
      <Box classes={{ root: classes.chipsBox }}>
        <Chip
          classes={{
            root: classes.statusBoxCount,
          }}
          label={
            <Typography variant="body1" classes={{ root: classes.countText }}>
              {`${dineInReceivedCount}x`}
            </Typography>
          }
        ></Chip>

        <Chip
          classes={{
            root: classes.statusBoxReceived,
          }}
          label={
            <Typography variant="body1" classes={{ root: classes.statusText }}>
              {"Received"}
            </Typography>
          }
        ></Chip>
        <Chip
          classes={{
            root: classes.statusBoxCount,
          }}
          label={
            <Typography variant="body1" classes={{ root: classes.countText }}>
              {`${dineInPreparingCount}x`}
            </Typography>
          }
        ></Chip>

        <Chip
          classes={{
            root: classes.statusBoxPreparing,
          }}
          label={
            <Typography variant="body1" classes={{ root: classes.statusText }}>
              {"Preparing"}
            </Typography>
          }
        ></Chip>
      </Box>
      <TableMap
        selectedOrder={selectedOrder}
        tablesConfig={tablesConfig}
        setSelectedOrderTypeOption={setSelectedOrderTypeOption}
        setSelectedTable={setSelectedTable}
        setSelectedOrder={setSelectedOrder}
        selectedTable={selectedTable}
        setSelectedOrderStateOption={setSelectedOrderStateOption}
      />
    </>
  )
}

export default TableMapCont
