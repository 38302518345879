import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import ToggleButton from "@material-ui/lab/ToggleButton"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  dialogContent: {
    minHeight: "5rem",
    maxHeight: "30rem",
    maxWidth: "15rem",
    padding: 0,
    overflowY: "auto",
  },
  dialogContentInsideBox: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  slotsGrid: {
    minHeight: "5rem",
  },

  slotsText: {
    fontSize: "1rem",
    textTransform: "none",
  },
  slotsTextBold: {
    fontSize: "1.5rem",
    textTransform: "none",
    fontWeight: 400,
  },
  slotBut: {
    borderRadius: "5rem",
    width: "4rem",
    height: "4rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blackTwo,
    "&:hover": {
      backgroundColor: `${theme.palette.common.redSelectedDark} !important`,
    },

    border: 0,
  },
  slotButSelected: {
    backgroundColor: `${theme.palette.common.redSelected} !important`,
  },

  topShaddow: {
    position: "sticky",
    zIndex: 1,
    top: 0,
    background: "linear-gradient(180deg, #00000070, transparent)",
    height: "1rem",
  },
  bottomShaddow: {
    position: "sticky",
    zIndex: 1,
    bottom: 0,
    background: "linear-gradient(0deg, #00000070, transparent)",
    height: "1rem",
  },
}))

const TableSelect = ({
  storeSettings,
  selectedTable,
  setSelectedTable,
  setSelectedOrderTypeOption,
  setSelectedOrder,
  handleClose,
  setSelectedOrderStateOption,
}) => {
  const classes = useStyles()

  return (
    <Box classes={{ root: classes.dialogContent }}>
      <Box classes={{ root: classes.topShaddow }}></Box>
      <Box classes={{ root: classes.dialogContentInsideBox }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          classes={{ root: classes.slotsGrid }}
          spacing={1}
        >
          {storeSettings.tableList.map((table, index) => {
            return (
              <Grid key={index} item xs={4}>
                <ToggleButton
                  color="primary"
                  variant="contained"
                  classes={{
                    root: classes.slotBut,
                    selected: classes.slotButSelected,
                  }}
                  value={table}
                  selected={selectedTable === table}
                  onChange={() => {
                    setSelectedTable(table)
                    setSelectedOrder({})
                    setSelectedOrderTypeOption({
                      value: "byTable",
                      label: `TABLE ${table}`,
                    })
                    setSelectedOrderStateOption({
                      value: "anyState",
                      label: "Any State",
                    })
                    handleClose()
                  }}
                >
                  <Typography
                    variant="body2"
                    classes={{ root: classes.slotsTextBold }}
                  >
                    {`${table}`}
                  </Typography>
                </ToggleButton>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <Box classes={{ root: classes.bottomShaddow }}></Box>
    </Box>
  )
}

export default TableSelect
