import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import ReadyOrderListItem from "./readyOrderListItem"
import { setReadyOrdersFunc } from "../helperFunc/mainImportFilter"

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderListBox: {
    width: "100%",
    paddingTop: "0.35rem",
    paddingLeft: "0.35rem",
  },
  orderListGrid: { width: "100%" },

  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 500px)",
    position: "relative",
  },
  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    color: theme.palette.common.blackNine,
    fontSize: "3rem !important",
  },
}))

const ReadyOrderList = ({
  ordersIn,
  user,
  localOrdersIn,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  selectedOrder,
  kitchenSettings,
  setSelectedOrder,
  setLocalOrdersIn,
}) => {
  const [readyOrders, setReadyOrders] = React.useState([])
  const classes = useStyles()
  React.useEffect(() => {
    setReadyOrders(setReadyOrdersFunc(ordersIn, localOrdersIn))
  }, [ordersIn, localOrdersIn])

  return (
    <Box classes={{ root: classes.orderListBox }}>
      <Grid container>
        {readyOrders.length > 0 ? (
          readyOrders.map((orderItem, index) => (
            <ReadyOrderListItem
              orderItem={orderItem}
              ordersIn={ordersIn}
              localOrdersIn={localOrdersIn}
              user={user}
              dispatchFeedback={dispatchFeedback}
              setAlarm={setAlarm}
              setOrdersIn={setOrdersIn}
              selectedOrder={selectedOrder}
              kitchenSettings={kitchenSettings}
              setSelectedOrder={setSelectedOrder}
              setLocalOrdersIn={setLocalOrdersIn}
              key={index}
            />
          ))
        ) : (
          <Box classes={{ root: classes.outBox }}>
            <Box classes={{ root: classes.inBox }}>
              <ErrorOutlineOutlinedIcon
                classes={{ root: classes.icon }}
              ></ErrorOutlineOutlinedIcon>
              <Typography variant="body2">{"No Orders Ready Yet"}</Typography>
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default ReadyOrderList
