import * as React from "react"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import NumInput from "../numInput"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../../helperFunc/handleError"
import { setLocalOrders } from "../helperFunc/mainImportFilter"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainBox: {},
  butAction: {
    padding: "0.25rem",
    borderRadius: "5rem",
    width: "8rem",
    height: "4rem",
    backgroundColor: theme.palette.common.localOrd,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.common.localOrdDark} !important`,
    },
  },

  butActionDisabled: {
    color: `${theme.palette.common.blackEight} !important`,
    backgroundColor: `${theme.palette.common.localOrdDark} !important`,
  },

  butActionBox: {
    flexWrap: "wrap",
    marginTop: "0.35rem",
  },

  butActionTextMain: {
    fontSize: "1.25rem",
    lineHeight: "1rem",
    fontWeight: 600,
  },
  butActionTextSecondary: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
}))

const LocalOrderAdd = ({
  user,
  dispatchFeedback,
  setLocalOrdersIn,
  localOrdersIn,
}) => {
  const [localOrdNum, setLocalOrdNum] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const classes = useStyles()
  const handleAdd = () => {
    setIsLoading(true)
    axios
      .post(
        process.env.GATSBY_STRAPI_URL + "/api/kitchen-setting/add-local-order",
        {
          orderNumber: localOrdNum,
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        const existingOrdInd = localOrdersIn.findIndex(
          element => element.orderNumber === parseInt(response.data.orderNumber)
        )

        if (existingOrdInd < 0) {
          let localOrdersInCopy = [...localOrdersIn]
          localOrdersInCopy.push(response.data)
          const { localOrdersRes } = setLocalOrders(localOrdersInCopy)
          setLocalOrdersIn(localOrdersRes)
        }

        setLocalOrdNum(0)
        setIsLoading(false)
      })
      .catch(error => {
        setLocalOrdNum(0)
        handleError(error, dispatchFeedback)
        setIsLoading(false)
      })
  }

  return (
    <Box classes={{ root: classes.mainBox }}>
      <NumInput
        num={localOrdNum}
        setNum={setLocalOrdNum}
        isDisabled={isLoading}
      >
        <Button
          disabled={isLoading || localOrdNum === 0}
          disableElevation
          fullWidth
          variant="contained"
          size="large"
          onClick={handleAdd}
          classes={{
            root: classes.butAction,
            disabled: classes.butActionDisabled,
          }}
        >
          <Box classes={{ root: classes.butActionBox }}>
            {isLoading ? (
              <CircularProgress color="inherit" size={"1.5rem"} />
            ) : (
              <>
                <Typography
                  variant="body1"
                  classes={{ root: classes.butActionTextMain }}
                >
                  {"ADD ORD"}
                </Typography>
                <Typography
                  variant="body1"
                  classes={{ root: classes.butActionTextSecondary }}
                >
                  {"IN-STORE"}
                </Typography>
              </>
            )}
          </Box>
        </Button>
      </NumInput>
    </Box>
  )
}

export default LocalOrderAdd
