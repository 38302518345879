import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: `${theme.palette.common.blackTwo} !important`,
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "0.5rem",
  },
}))

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexWrap: "wrap",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
  },
  grouped: {
    border: "none",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

const StyledToggleButton = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    borderRadius: "0.25rem !important",
    padding: "0.25rem 1rem",
    fontWeight: 600,
  },
  selected: {
    backgroundColor: `${theme.palette.common.redSelected} !important`,
  },
}))(ToggleButton)

export default function ToggleComp({
  optionsIn,
  selectedItem,
  handleSelectedChange,
  setAnchorEl,
}) {
  const classes = useStyles()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          value={selectedItem.value}
          exclusive
          onChange={handleSelectedChange}
        >
          {optionsIn.map((option, index) => (
            <StyledToggleButton
              key={index}
              value={option.value}
              onClick={option.popBox ? handleClick : undefined}
            >
              {option.value === selectedItem.value
                ? selectedItem.label
                : option.label}
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  )
}
