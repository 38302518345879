import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import OrderListItem from "./OrderListItem"
import NoFoodOutlinedIcon from "@mui/icons-material/NoFoodOutlined"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderListBox: {
    width: "100%",
    paddingTop: "0.5rem",
    paddingLeft: "0.5rem",
  },
  orderListGrid: { width: "100%" },
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 240px)",
    position: "relative",
  },
  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "45%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    color: theme.palette.common.blackNine,
    fontSize: "3rem !important",
  },
}))

const OrdersList = ({
  ordersIn,
  selectedOrder,
  setSelectedOrder,
  kitchenSettings,
  selectedOrderTypeOption,
  selectedOrderStateOption,
  searchNum,
  selectedTable,
}) => {
  const classes = useStyles()
  let count = 0
  return (
    <Box classes={{ root: classes.orderListBox }}>
      <Grid container>
        {ordersIn.map((orderItem, index) => {
          if (
            (selectedOrderTypeOption.value === "anyType" ||
              selectedOrderTypeOption.value === orderItem.orderType) &&
            (selectedOrderStateOption.value === "anyState" ||
              selectedOrderStateOption.value === orderItem.status)
          ) {
            count++
            return (
              <OrderListItem
                orderItem={orderItem}
                key={index}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                kitchenSettings={kitchenSettings}
              />
            )
          } else if (
            selectedOrderTypeOption.value === "byTable" &&
            selectedTable === orderItem.orderTableNumber &&
            (selectedOrderStateOption.value === "anyState" ||
              selectedOrderStateOption.value === orderItem.status)
          ) {
            count++
            return (
              <OrderListItem
                orderItem={orderItem}
                key={index}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                kitchenSettings={kitchenSettings}
              />
            )
          } else if (
            selectedOrderTypeOption.value === "byOrderNo" &&
            searchNum === orderItem.id &&
            (selectedOrderStateOption.value === "anyState" ||
              selectedOrderStateOption.value === orderItem.status)
          ) {
            count++
            return (
              <OrderListItem
                orderItem={orderItem}
                key={index}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                kitchenSettings={kitchenSettings}
              />
            )
          } else {
            return null
          }
        })}
      </Grid>
      {count === 0 ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <NoFoodOutlinedIcon
              classes={{ root: classes.icon }}
            ></NoFoodOutlinedIcon>
            <Typography variant="body2">{"No Orders Found"}</Typography>
            <Typography variant="body1">
              {"Adjust the filter to view more orders"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default OrdersList
